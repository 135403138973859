import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入eleui核心库
import ElementUI from 'element-ui';
// 引入v-charts
import VeLine from 'v-charts/lib/line.common'
import VePie from 'v-charts/lib/pie.common'
// 引入样式
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
// 设置v-charts
Vue.component(VeLine.name, VeLine)
Vue.component(VePie.name, VePie)

// 引入自定的 filter 函数
import filters from './filters';
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));

//bus 总线传值
Vue.prototype.bus = new Vue();

Vue.directive('auth', {
  // 当被绑定的元素插入到 DOM 中时……
  bind: function (el, binding) {
    // 聚焦元素
    // console.log(el.style,binding,'---------')
    // console.log(binding.value);
    const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources
    // const _resources = "ALL"
		if(_resources=='ALL'){
			el.style.visible = true
		}else {
			if (_resources && _resources.split(';').indexOf(binding.value) !== -1 || binding.value=='ALL') {
			  el.style.visible = true
			} else {
			  el.style.display = 'none'
			}
		}
    el.focus()
  }
})

Vue.directive('alterELDialogMarginTop'/*修改elementUI中el-dialog顶部的距离,传入值eg:{marginTop:'5vh'} */, {
  inserted(el, binding, vnode) {
    el.firstElementChild.style.marginTop = binding.value.marginTop
  }
})

router.beforeEach((to, from, next) => {
  // ElementUI.Message.closeAll() //清除提示
  // 判断用户是否已经登录 0503
  // 0530 设置登录过期定时器
  if (to.path !== '/login') {
    
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (!userInfo) {
      let url = window.location.href;
      const extensionRegex = /\.txt$/; // 正则表达式匹配.txt结尾
      if (!extensionRegex.test(url)) {
        next({ path: "/login" });
      } else {
        window.location.href = url;
      }
    } else {
      next()
    }

  } else {
    next()
  }
})

Vue.prototype.$enter = (path,callback)=>{
  document.onkeydown = function () {
    let key = window.event.keyCode;
    if (key === 13 && window.location.hash === '#'+path) {
      callback(); //方法
    }
  };
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
