export default [{
	path: "/operation",
	name: "operation",
	// redirect: '/operation/deal_jytz',
	redirect: '/operation/manage_jydd',

	component: () => import("@/views/operation_yw/index"),
	children: [
		{ //交易订单管理
			path: "/operation/manage_jydd",
			name: "manage_jydd",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_jydd/index"),
			meta: {
				tab: '交易订单管理'
			}
		},
		{ //退款订单管理
			path: "/operation/manage_tkdd",
			name: "manage_tkdd",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_tkdd/index"),
			meta: {
				tab: '退款订单管理'
			}
		},

		{ //代付订单管理
			path: "/operation/manage_dfdd",
			name: "manage_dfdd",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_dfdd/index"),
			meta: {
				tab: '代付订单管理'
			}
		},
		{ //分账交易订单管理
			path: "/operation/manage_fzjy",
			name: "manage_fzjy",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_fzjy/index"),
			meta: {
				tab: '分账交易订单管理'
			}
		},
		{ //分账代付订单管理
			path: "/operation/manage_fzdf",
			name: "manage_fzdf",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_fzdf/index"),
			meta: {
				tab: '分账代付订单管理'
			}
		},
		{ //分账结算订单管理
			path: "/operation/manage_fzjs",
			name: "manage_fzjs",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_fzjs/index"),
			meta: {
				tab: '分账结算订单管理'
			}
		},
		{ //分账转账订单管理
			path: "/operation/manage_fzzz",
			name: "manage_fzzz",
			component: () => import("@/views/operation_yw/inform_ddgl/manage_fzzz/index"),
			meta: {
				tab: '分账转账订单管理'
			}
		},


		// -------------------------------------
		{ //交易通知查询
			path: "/operation/deal_jytz",
			name: "deal_jytz",
			component: () => import("@/views/operation_yw/inform_tzgl/deal_jytz/index"),
			meta: {
				tab: '交易通知查询'
			}
		},
		{ //交易详情
			path: "/operation/deal_xq",
			name: "deal_xq",
			component: () => import("@/views/operation_yw/inform_tzgl/deal_jytz/deal_xq"),
			meta: {
				title: '通知查询',
				tab: '查看交易通知详情'
			}
		},
		{ //退款
			path: "/operation/refund_tktz",
			name: "refund_tktz",
			component: () => import("@/views/operation_yw/inform_tzgl/refund_tktz/index"),
			meta: {
				tab: '退款通知查询'
			}
		},
		{ //退款详情
			path: "/operation/refund_xq",
			name: "refund_xq",
			component: () => import("@/views/operation_yw/inform_tzgl/refund_tktz/refund_xq"),
			meta: {
				title: '退款通知查询',
				tab: '查看退款通知详情'
			}
		},
		{ //代付
			path: "/operation/applyfor_dftz",
			name: "applyfor_dftz",
			component: () => import("@/views/operation_yw/inform_tzgl/applyfor_dftz/index"),
			meta: {
				tab: '付款通知查询'
			}
		},
		{ //代付详情
			path: "/operation/applyfor_xq",
			name: "applyfor_xq",
			component: () => import("@/views/operation_yw/inform_tzgl/applyfor_dftz/applyfor_xq"),
			meta: {
				title: '代付详情',
				tab: '查看付款通知详情'
			}
		},
		{ //分账代付
			path: "/operation/account_fzdftz",
			name: "account_fzdftz",
			component: () => import("@/views/operation_yw/inform_tzgl/account_fzdftz/index"),
			meta: {
				tab: '分账代付通知查询'
			}
		},
		{ //分账代付详情
			path: "/operation/account_dfxq",
			name: "account_dfxq",
			component: () => import("@/views/operation_yw/inform_tzgl/account_fzdftz/account_xq"),
			meta: {
				tab: '查看分账代付通知详情'
			}
		},
		{ //分账
			path: "/operation/account_fztz",
			name: "account_fztz",
			component: () => import("@/views/operation_yw/inform_tzgl/account_fztz/index"),
			meta: {
				tab: '分账交易通知查询'
			}
		},
		{ //分账详情
			path: "/operation/account_xq",
			name: "account_xq",
			component: () => import("@/views/operation_yw/inform_tzgl/account_fztz/account_xq"),
			meta: {
				title: '交易查询',
				tab: '查看分账交易通知详情'
			}
		},
		{ //担保
			path: "/operation/guarantee_dbtz",
			name: "guarantee_dbtz",
			component: () => import("@/views/operation_yw/inform_tzgl/guarantee_dbtz/index"),
			meta: {
				tab: '分账担保通知查询'
			}
		},
		{ //担保详情
			path: "/operation/guarantee_xq",
			name: "guarantee_xq",
			component: () => import("@/views/operation_yw/inform_tzgl/guarantee_dbtz/guarantee_xq"),
			meta: {
				tab: '查看分账担保通知详情'
			}
		},
		{ //结算
			path: "/operation/refund_jstz",
			name: "refund_jstz",
			component: () => import("@/views/operation_yw/inform_tzgl/refund_jstz/index"),
			meta: {
				tab: '分账结算通知查询'
			}
		},
		{ //结算详情
			path: "/operation/refund_JSxq",
			name: "refund_JSxq",
			component: () => import("@/views/operation_yw/inform_tzgl/refund_jstz/refund_xq"),
			meta: {
				tab: '查看分账结算通知详情'
			}
		},
		{ //结算
			path: "/operation/transfer_zztz",
			name: "transfer_zztz",
			component: () => import("@/views/operation_yw/inform_tzgl/transfer_zztz/index"),
			meta: {
				tab: '分账转账通知查询'
			}
		},
		{ //结算详情
			path: "/operation/refund_ZZxq",
			name: "refund_ZZxq",
			component: () => import("@/views/operation_yw/inform_tzgl/transfer_zztz/refund_xq"),
			meta: {
				tab: '查看分账转账通知详情'
			}
		},
		{ //收款测试
			path: "/operation/test_result",
			name: "test_result",
			component: () => import("@/views/operation_yw/test_result/index"),
			meta: {
				tab: '收款测试'
			}
		},
		{ //默认
			path: "/operation404",
			name: "default",
			component: () => import("@/views/default"),
		}
	]
},

]
