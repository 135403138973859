export const URL_API = {
  // baseURL: '/', //本地api
  // upload: '/manager/common/upload', //本地上传文件接口
  // codeUrl: '/manager/common/imagecode', //本地二维码

  // upload: 'http://pay-plat-api.test.youthsrun.com/manager/common/upload', //测试上传
  // baseURL: 'http://pay-plat-api.test.youthsrun.com', //测试api
  // codeUrl: 'http://pay-plat-api.test.youthsrun.com/manager/common/imagecode',//测试二维码
  // placeOrderUrl:"https://apitest.youthsrun.com/" //测试环境 下单地址

  // upload: 'https://managertest.youthsrun.com/manager/common/upload', //测试上传
  // baseURL: 'https://managertest.youthsrun.com', //测试api
  // codeUrl: 'https://managertest.youthsrun.com/manager/common/imagecode',//测试二维码
  // placeOrderUrl:"https://apitest.youthsrun.com/" //测试环境 下单地址

  upload: "https://api-cloud.youths.run/manager/common/upload", //正式上传
  baseURL: "https://api-cloud.youths.run", //正式api
  codeUrl: "https://api-cloud.youths.run/manager/common/imagecode", //正式二维码
  placeOrderUrl: "https://api.youths.run/", //正式环境 下单地址

  // upload: 'https://cloud.api.issue.youthsrun.com/manager/common/upload', //预生产上传
  // baseURL: 'https://cloud.api.issue.youthsrun.com', //预生产
  // codeUrl: 'https://cloud.api.issue.youthsrun.com/manager/common/imagecode',//预生产验证码
  // placeOrderUrl:"https://api.issue.youthsrun.com/" //预生产环境 下单地址

  // upload: 'https://supply.api.youthsrun.com/manager/common/upload', //测试上传2
  // baseURL: 'https://supply.api.youthsrun.com', //测试api2
  // codeUrl: 'https://supply.api.youthsrun.com/manager/common/imagecode',//测试二维码2

  // baseURL: "http://192.168.0.33:9102",
  // upload: 'http://192.168.0.33:9102/manager/common/upload',
};
