import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import router from '@/router'
import { URL_API } from '@/utils/apiUrl.js'

import { Message, Loading } from 'element-ui';
var show = true
const service = axios.create({
  headers: { 
    'Content-Type': 'application/json;chartset=utf-8',
    'token':store.getters.getToken,
   },
  baseURL: URL_API.baseURL,
  timeout: 600000,
  transformRequest: [(data) => {
    return JSON.stringify(data);
  }],
  withCredentials: true,
});
let loadingInstance = null
const err = (error) => {
  if (loadingInstance && loadingInstance.close) {
    loadingInstance.close()
  }
  if (JSON.stringify(error).indexOf('600000ms') !== -1) {
    Message.error('请求超时！！');
    return Promise.reject(error)
  }
  if (error.response) {

    const data = error.response.data
    if (data.errorCode == "020030") {
      if (show) {
        Message.error('会话已超时，请重新登录');
        show = false
        setTimeout(() => {
          show = true
        }, 1000)
      }
      router.push({ name: 'login' })
    }
  }
  return Promise.reject(error)
}

// 请求拦截器  (若token存在则在请求头中加token，不存在也继续请求)
service.interceptors.request.use(config => {
  // console.log('请求拦截',config)
  
  config.headers.token = store.getters.getToken;

  if (config.params && config.params.loading) {
    delete config.params.loading
    loadingInstance = Loading.service({
      lock: true,
      background: 'rgba(255, 255, 255, 0.5)'
    });
  }
  return config
}, err)

// 响应拦截器
service.interceptors.response.use((response) => {
  // console.log('响应拦截器', response)

  if (loadingInstance && loadingInstance.close) {
    loadingInstance.close()
  }
  if (response.data && !response.data.hasOwnProperty("resultStatus")) {
    return response.data
  }
  if (response.data.resultStatus) {
    return response.data
  } else {
    console.log('响应拦截', response)

    if (response.data.errorCode == '020030') {
      router.push({ name: 'login' })
    } else {
      Message.close()
      // Message.error(response.data ? response.data.errorMessage : '请求错误!!');

      if (response.config.Headers&&response.config.Headers.type==1) {
        Message.close()

      } else {
      console.log('响应拦截22', response)

        Message.error(response.data ? response.data.errorMessage : '请求错误!!');

      }
      return response.data
    }
  }
}, err)
export default service
