export default [{
		path: "/data",
		name: "data",
		redirect: '/data/deal_jytz',
		component: () => import("@/views/data_sj/index"),
		children: [{ //交易概况
				path: "/data/deal_jytz",
				name: "deal_jytz",
				component: () => import("@/views/data_sj/clearing_qsgl/deal_jygk/index"),
				meta: {
					title: '交易概括',
					tab: '交易概况'
				}
			},
			{ //收益概况
				path: "/data/earnings_sygk",
				name: "earnings_sygk",
				component: () => import("@/views/data_sj/clearing_qsgl/earnings_sygk/index"),
				meta: {
					title: '收益概况',
					tab: '收益概况'
				}
			},
			{ //商户统计
				path: "/data/shop_shtj",
				name: "shop_shtj",
				component: () => import("@/views/data_sj/data_sjfx/shop_shtj/index"),
				meta: {
					title: '数据分析',
					tab: '商户统计'
				}
			},
			{ //店铺统计
				path: "/data/shop_mdtj",
				name: "shop_mdtj",
				component: () => import("@/views/data_sj/data_sjfx/shop_mdtj/index"),
				meta: {
					title: '店铺统计',
					tab: '店铺统计'
				}
			},
			{ //时段分析
				path: "/data/time_sdfx",
				name: "time_sdfx",
				component: () => import("@/views/data_sj/data_sjfx/time_sdfx/index"),
				meta: {
					title: '时段分析',
					tab: '时段分析'
				}
			},
			{ //终端IP分享
				path: "/data/ip",
				name: "data_ip",
				component: () => import("@/views/data_sj/data_sjfx/ip/index"),
				meta: {
					title: '终端IP分析',
					tab: '终端IP分析'
				}
			},
			{ //服务商汇总
				path: "/service_fwshz",
				name: "service_fwshz",
				component: () => import("@/views/data_sj/clearing_qsgl/service_fwshz/index"),
			},
			{ //代理商汇总  
				path: "/agent_dlshz",
				name: "agent_dlshz",
				component: () => import("@/views/data_sj/clearing_qsgl/agent_dlshz/index"),
				meta: {
					tab: '代理商汇总'
				}
			},
			{ //代理商汇总
				path: "/agent_dlshz_detail",
				name: "agent_dlshz_detail",
				component: () => import("@/views/data_sj/clearing_qsgl/agent_dlshz/detail"),
				meta: {
					title: '代理商汇总',
					tab: '查看代理商汇总详情'
				}
			},
			{ //商户汇总  
				path: "/commercial_shhz",
				name: "commercial_shhz",
				component: () => import("@/views/data_sj/clearing_qsgl/commercial_shhz/index"),
				meta: {
					tab: '商户汇总'
				}
			},
			{ //商户汇总
				path: "/commercial_shhz_detail",
				name: "commercial_shhz_detail",
				component: () => import("@/views/data_sj/clearing_qsgl/commercial_shhz/detail"),
				meta: {
					title: '商户汇总',
					tab: '查看商户汇总详情'
				}
			},
			{ //渠道汇总
				path: "/channel_qdhz",
				name: "channel_qdhz",
				component: () => import("@/views/data_sj/clearing_qsgl/channel_qdhz/index"),
				meta: {
					tab: '渠道汇总'
				}
			},
			{ //渠道汇总
				path: "/channel_qdhz_detail",
				name: "channel_qdhz_detail",
				component: () => import("@/views/data_sj/clearing_qsgl/channel_qdhz/detail"),
				meta: {
					title: '渠道汇总',
					tab: '查看渠道汇总详情'
				}
			},
			{ //机构汇总
				path: "/organization_jghz",
				name: "organization_jghz",
				component: () => import("@/views/data_sj/clearing_qsgl/organization_jghz/index"),
				meta: {
					tab: '机构汇总'
				}
			},
			{ //机构汇总详情
				path: "/organization_jghz_detail",
				name: "organization_jghz_detail",
				component: () => import("@/views/data_sj/clearing_qsgl/organization_jghz/detail"),
				meta: {
					title: '机构汇总',
					tab: '查看机构汇总详情'
				}
			},
			{ //对账异常
				path: "/abnormal_dzyc",
				name: "abnormal_dzyc",
				component: () => import("@/views/trading_jy/reconciliation_dz/abnormal_dzyc/index"),
				meta: {
					title: '对账异常',
					tab: '对账异常'
				}
			},
			{ //对账概要
				path: "/outline_dzgy",
				name: "outline_dzgy",
				component: () => import("@/views/trading_jy/reconciliation_dz/outline_dzgy/index"),
				meta: {
					title: '对账概要',
					tab: '对账概要'
				}
			},
			{ //商户对账  
				path: "/merchants_shdz",
				name: "merchants_shdz",
				component: () => import("@/views/trading_jy/reconciliation_dz/merchants_shdz/index"),
				meta: {
					title: '商户对账',
					tab: '商户对账'
				}
			},
			{ //对账任务
				path: "/reconciliationtask_dzrw",
				name: "reconciliationtask_dzrw",
				component: () => import("@/views/trading_jy/reconciliation_dz/reconciliationtask_dzrw/index"),
				meta: {
					title: '对账任务',
					tab: '对账任务'
				}
			},
			{ //账单查询
				path: "/bill_search",
				name: "bill_search",
				component: () => import("@/views/data_sj/bill_mananger/search"),
				meta: {
					tab: '账单查询'
				}
			},
			{ //账单查询
				path: "/bill_collect",
				name: "bill_collect",
				component: () => import("@/views/data_sj/bill_mananger/collect"),
				meta: {
					tab: '资金汇总'
				}
			},
			{ //账单查询
				path: "/bill_collect_sub",
				name: "bill_collect_sub",
				component: () => import("@/views/data_sj/bill_mananger/collect_sub"),
			},
      { //默认
        path: "/data404",
        name: "default",
        component: () => import("@/views/default"),
      }
		]
	}

]
