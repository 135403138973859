import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import trading from "./trading/index"
import data from "./data/index"
import management from "./management/index"
import operation from "./operation/index"
import user from "./user/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children:[
      ...trading,
      ...data,
      ...management,
      ...operation,
      ...user
    ]
  },
  {
    path:'/login',
    name: 'login',
    component:()=>import('@/views/login/login2')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
