<template>
  <div id="mianTemp">
    <div class="mianHeader1" :class="isActiveHead==true ? 'hideSidebar' : 'openSidebar'">
      <div class="mianHeader">
        <div class="logo clear">
          <span>
            <div class="logoImg"></div>
          </span>
          <i @click="toggleClick">
            管理后台
            <div class="openImg" :class="isActiveHead==true ? '':'is-active'" alt=""/>
          </i>
        </div>
        <div class="headnavBox">
          <ul class="headnav">
            <li
              v-auth="'ACCOUNT'"
              :class="active_route == 'user' ? 'active' : ''"
            >
              <router-link to="/user">档案中心</router-link>
            </li>
            <li
              v-auth="'TRADE'"
              :class="active_route == 'transaction' ? 'active' : ''"
            >
              <router-link to="/">交易中心</router-link>
            </li>
            <li v-auth="'DATA'" :class="active_route == 'data' ? 'active' : ''">
              <router-link to="/data">数据中心</router-link>
            </li>
            <li
              v-auth="'OPERATION'"
              :class="active_route == 'operation' ? 'active' : ''"
            >
              <router-link to="/operation">运维中心</router-link>
            </li>
            <li
              v-auth="'SYSTEM'"
              :class="active_route == 'management' ? 'active' : ''"
            >
              <router-link to="/management">系统管理</router-link>
            </li>
          </ul>
        </div>
        <div class="admin">
          <el-menu class="user-name" mode="horizontal" @select="handleSelect">
            <el-submenu index="2">
              <template slot="title">{{ userInfo.username }}</template>
              <el-menu-item index="2-1" class="tab no_change">
                <img src="@/assets/suo2.png" alt="" class="iconImg" />
                <span>修改密码</span>
              </el-menu-item>
              <el-menu-item index="2-2" class="tab no_change">
                <img src="@/assets/exit.png" alt="" class="iconImg" />
                <span>退出登录</span>
              </el-menu-item>
            </el-submenu>
          </el-menu>
          <!-- <p>useryouths.run  <i class="el-icon-arrow-down"></i> </p> -->
        </div>
      </div>
    </div>
    <div class="contentBigBox">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <div class="footer1">
      <div class="footer">
        <span
          >Copyright © 2019 - {{ thisYear }} Youthsrun. All Rights Reserved. 有数互动
          版权所有</span
        >
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="passShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>旧密码</div>
          <el-input
            v-model="formData.oldPassword"
            show-password
            placeholder="输入旧密码"
            class="width100"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>新密码</div>
          <el-input
            v-model="formData.newPassword"
            show-password
            placeholder="输入新密码"
            class="width100"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>确认密码</div>
          <el-input
            v-model="formData.reNewPassword"
            show-password
            placeholder="再次输入新密码"
            class="width100"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="passShow = false">取 消</el-button>
        <el-button class="addBt" @click="editPass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { setPass, layout } from "@/api/login.js";
import md5 from "js-md5";
export default {
  data() {
    return {
      crumbs: "交易中心",
      active_route: "交易中心",
      passShow: false,
      formData: {
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
      },
      userInfo: "",
      isActiveHead:this.$store.state.isActiveHead,
      thisYear:"",
    };
  },
  watch: {
    $route: {
      handler: function (val) {
        this.active_route = val.matched[1].name;
        console.log(this.active_route,"'this.active_route'")
      },
      // 深度观察监听
      deep: true,
    },
  },
  created() {
    let thisYearDate = new Date();
    this.thisYear = thisYearDate.getFullYear();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (this.userInfo.needChangePassword) {
      this.resetPass();
    }
  },
  methods: {
    toggleClick() {
      this.isActiveHead = !this.isActiveHead;
      this.bus.$emit("isActiveHead", this.isActiveHead);
      console.log(this.isActiveHead,"fffff")
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
    handleSelect(index) {
      if (index == "2-1") {
        this.setPassShow();
      }
      if (index == "2-2") {
        this.layout();
      }
    },
    // 30天重置密码
    resetPass() {
      this.$confirm("密码已长时间没修改，请尽快更新", "提示", {
        confirmButtonText: "修改",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.setPassShow();
      }).catch(()=>{});
    },
    // 修改密码展示
    setPassShow() {
      this.formData = {
        oldPassword: "",
        newPassword: "",
        reNewPassword: "",
      };
      this.passShow = true;
    },
    // 修改密码
    editPass() {
      let data = JSON.parse(JSON.stringify(this.formData));
      let passwordTest = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
      if (!data.oldPassword) {
        this.$message.error("请输入旧密码");
        return;
      }
      if (!data.newPassword) {
        this.$message.error("请输入新密码");
        return;
      }
      // 正则验证密码复杂度   
      if (!passwordTest.test( data.newPassword)) {
        return this.$message.error(
          "密码必须包含：大小写字母, 数字, 特殊符号, 长度至少8位以上"
        );
      }
      if (!data.reNewPassword) {
        this.$message.error("请再次输入新密码");
        return;
      }
      if (data.newPassword != data.reNewPassword) {
        this.$message.error("两次输入新密码不一致");
        return;
      }
      data.oldPassword = md5(data.oldPassword);
      data.newPassword = md5(data.newPassword);
      delete data.reNewPassword;
      setPass(data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("修改成功");
          sessionStorage.clear();
          setTimeout(() => {
            this.$router.push({
              name: "login",
            });
            this.passShow = false;
          }, 1000);
        }
      });
    },
    // 退出登陆
    layout() {
      layout().then((res) => {
        if (res) {
          sessionStorage.clear();
          this.$router.push({
            name: "login",
          });
        }
      });
    },
  },
};
</script>

<style>
#mianTemp{
  width: 100%;
  min-width: 1300px;
}
.mianHeader1 {
  width: 100%;
  height: 75px;
  background: rgba(255, 255, 255, 1);
  position: relative;
  top: 0;
}
.mianHeader1 .mianHeader {
  width: 1300px;
  height: 100%;
  margin: 0 auto;
}
.mianHeader1.hideSidebar .mianHeader {
  width: 1300px;
}
.mianHeader1.openSidebar .mianHeader{
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
}

#mian {
  width: 100%;
  min-height: 800px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
}

.logo {
  float: left;
}

.logo i {
  display: inline-block;
  float: right;
  margin-left: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 75px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.logo i .openImg{
  width: 20px;
  height: 20px;
  background: url(../assets/open.png);
}

.logo i .is-active{
  transform: rotateY(180deg);
}

.logo span {
  flex: left;
  display: inline-block;
  width: 142px;
  height: 75px;
  position: relative;
}

.logo span .logoImg {
  width: 142px;
  height: 24px;
  background-image: url(../assets/logox.png);
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.logo i:hover{
  color: #48b8b6;
}

.logo i:hover .openImg{
  background-image: url(../assets/open-on.png);
}

.contentBigBox {
  padding: 20px 0;
  width: 100%;
  height: 100%;
  background: #eff3f8;
}

.contentBigBox .content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}



.headnavBox {
  float: left;
  position: relative;
  left: calc(50% - 220px);
}

.headnav {
  float: left;
  position: relative;
  right: 50%;
}

.headnav li {
  float: left;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 75px;
  margin-right: 40px;
}

.headnav li.active {
  border-bottom: 0;
}

.headnav li.active a {
  color: #48b8b6;
}

.headnav li:hover a {
  color: #48b8b6;
}
.headnav li:last-child {
  margin-right: 0;
}


.admin {
  display: inline;
  float: right;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  line-height: 75px;
  overflow-x: hidden;
  margin-top: 8px;
}

.admin .el-menu--horizontal>.el-submenu .el-submenu__title:hover , .admin .el-menu--horizontal>.el-submenu .el-submenu__title:focus{
  color: var(--themeColor) !important;
}

.admin ul {
  border: 0 !important;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 0 !important;
  color: #303133;
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: #fff !important;
}

#subme .el-menu-item {
  width: 100px !important;
}

/* .admin .el-menu .el-menu--popup .el-menu--popup-bottom-start{
  width: 100px !important;
  overflow-x: hidden;
} */

.admin .el-menu--collapse .el-menu .el-submenu, .el-menu--popup {
  top: 3px !important;
  min-width: 140px !important;
  left: 13px;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.footer1 {
  width: 100%;
  height: 75px;
  background: rgba(255, 255, 255, 1);
}

.footer span {
  display: inline-block;
  margin-left: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 44px;
}


.list .right_con h5 a, .list .left_con h5 a {
  color: #008aff !important;
  font-size: 14px;
  font-family: Microsoft YaHei;
}

.active {
  color: rgba(72, 184, 182, 1);
  border-bottom: 3px solid #48b8b6;
}
</style>
<style scoped>
.tab {
  position: relative;
}

.tab span {
  display: block;
  text-align: center;
}

.iconImg {
  width: auto;
  height: 12px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  width: 200px;
  margin: 0 auto;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6 !important;
  border-color: #66b2af !important;
  color: #ffffff !important;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff !important;
  color: #333333 !important;
  border-color: #d7dae2 !important;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
.no_change.el-menu-item.is-active::before,
.no_change.el-menu-item.is-active {
  background-color: #fff !important;
}
</style>
