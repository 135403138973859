export default [{
  path: "/user",
  name: "user",
  redirect: '/user/organization_jggl',
  component: () => import("@/views/user_yh/index"),
  children: [
    { //小程序管理 配置信息
      path: "/user/applet_list_detail_set",
      name: "applet_list_detail_set",
      component: () => import("@/views/user_yh/applet/applet_list/applet_list_detail_set"),
      meta: {
        tab: '小程序详情 - 配置信息'

      }
    },
    { //小程序管理 详情
      path: "/user/applet_list_detail",
      name: "applet_list_detail",
      component: () => import("@/views/user_yh/applet/applet_list/applet_list_detail"),
      meta: {
        tab: '小程序详情 - 基本信息'
      }
    },
    { //小程序管理 第三方列表
      path: "/user/applet_list_add",
      name: "applet_list_add",
      component: () => import("@/views/user_yh/applet/applet_list/applet_list_add"),
      meta: {
        title: '新增小程序',
        tab: '新增小程序'
      }
    },
    { //小程序管理 第三方列表
      path: "/user/applet_list",
      name: "applet_list",
      component: () => import("@/views/user_yh/applet/applet_list/index"),
      meta: {
        title: '小程序列表',
        tab: '小程序列表'
      }
    },
    { //小程序管理 第三方列表
      path: "/user/third_party_add",
      name: "third_party_add",
      component: () => import("@/views/user_yh/applet/third_party/third_party_add"),
      meta: {
        title: '新增第三方',
        tab: '新增第三方'
      }
    },
    { //小程序管理 修改第三方列表
      path: "/user/third_party_edit",
      name: "third_party_edit",
      component: () => import("@/views/user_yh/applet/third_party/third_party_add"),
      meta: {
        tab: '编辑第三方'
      }
    },
    { //小程序管理 第三方列表
      path: "/user/third_party_xp",
      name: "third_party_xp",
      component: () => import("@/views/user_yh/applet/third_party/third_party_xp"),
      meta: {
        tab: '查看第三方详情'
      }
    },
    { //小程序管理 第三方列表
      path: "/user/third_party",
      name: "third_party",
      component: () => import("@/views/user_yh/applet/third_party/index"),
      meta: {
        title: '第三方列表',
        tab: '第三方列表'
      }
    },

    { //小程序管理 终端小程序配置
      path: "/user/terminal_config",
      name: "terminal_config",
      component: () => import("@/views/user_yh/applet/terminal_config/index"),
      meta: {
        title: '终端小程序配置',
        tab: '终端小程序配置'
      }
    },
    { //进件队列  
      path: "/user/Into_jjdl",
      name: "Into_jjdl",
      component: () => import("@/views/user_yh/shop_mdgl/Into_jjdl/index"),
      meta: {
        title: '进件队列',
        tab: '进件队列'
      }
    },
    { //店铺列表报备商户号信息详情修改
      path: "/user/shoplist_xq_shop_xg",
      name: "shoplist_xq_shop_xg",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/shoplist_xq_shop_xg"),
      meta: {
        title: '店铺详情',
        tab: '报备商户信息 - 修改 '

      }
    },
    { //店铺列表报备商户号信息详情产品报备
      path: "/user/shoplist_xq_shop_cp",
      name: "shoplist_xq_shop_cp",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/shoplist_xq_shop_cp"),
      meta: {
        title: '店铺详情',
        tab: '报备商户信息 - 产品报备'
      }
    },
    { //店铺列表报备商户号信息详情特约配置
      path: "/user/shoplist_xq_shop_ty",
      name: "shoplist_xq_shop_ty",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/shoplist_xq_shop_ty"),
      meta: {
        title: '店铺详情',
        tab: '报备商户信息 - 特约配置'

      }
    },
    { //店铺列表报备商户号信息详情
      path: "/user/shoplist_xq_shop",
      name: "shoplist_xq_shop",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/shoplist_xq_shop"),
      meta: {
        title: '店铺详情',
        tab: '报备商户号信息'
      }
    },
    { //店铺列表详情  
      path: "/user/shoplist_xq",
      name: "shoplist_xq",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/shoplist_xq"),
      meta: {
        tab: '查看店铺详情'
      }
    },
    { //店铺列表新增手工录入配置信息
      path: "/user/add_shoplist_user_pz",
      name: "add_shoplist_user_pz",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist_user_pz"),
      meta: {
        title: '新增店铺',
        tab: '新增店铺 - 配置信息'
      }
    },
    { //店铺列表新增手工录入基本信息
      path: "/user/add_shoplist_user",
      name: "add_shoplist_user",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist_user"),
      meta: {
        title: '新增店铺',
        tab: '新增店铺 - 基本信息'
      }
    },
    { //店铺列表修改手工录入基本信息
      path: "/user/add_shoplist_user_edit",
      name: "add_shoplist_user_edit",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist_user")
    },
    { //店铺列表新增API受益人信息
      path: "/user/add_shoplist_syr",
      name: "add_shoplist_syr",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist_syr"),
      meta: {
        title: '新增店铺',
        tab: '新增店铺'

      }
    },
    { //店铺列表新增API配置信息
      path: "/user/add_shoplist_pz",
      name: "add_shoplist_pz",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist_pz"),
      meta: {
        title: '新增店铺',
        tab: '新增店铺'

      }
    },
    { //店铺列表新增API结算信息
      path: "/user/add_shoplist_js",
      name: "add_shoplist_js",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist_js"),
      meta: {
        title: '新增店铺',
        tab: '新增店铺'

      }
    },
    { //店铺列表新增API基本信息
      path: "/user/add_shoplist",
      name: "add_shoplist",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist"),
      meta: {
        title: '新增店铺',
        tab: '新增店铺'
      }
    },
    { //店铺列表修改API基本信息
      path: "/user/add_shoplist_edit",
      name: "add_shoplist_edit",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/add_shoplist"),
      meta: {
        title: '店铺详情',
        tab: '编辑店铺基本信息'
      }
    },
    { //店铺列表  
      path: "/user/shoplist_mdlb",
      name: "shoplist_mdlb",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/index"),
      meta: {
        title: '店铺列表',
        tab: '店铺列表'
      }
    },
    { //店铺列表  
      path: "/user/entryConfig",
      name: "entryConfig",
      component: () => import("@/views/user_yh/shop_mdgl/shoplist_mdlb/entryConfig"),
      meta: {
        title: '进件配置',
        tab: '进件配置'
      }
    },
    { //店铺列表  
      path: "/user/shop_dpcx",
      name: "shop_dpcx",
      component: () => import("@/views/user_yh/shop_mdgl/shop_dpcx/index"),
      meta: {
        title: '店铺查询',
        tab: '店铺查询'
      }
    },
    { //担保查询  
      path: "/user/guarantee_dbcx",
      name: "guarantee_dbcx",
      component: () => import("@/views/user_yh/subaccount_fzsh/guarantee_dbcx/index"),
    },
    { //自动结算查询  
      path: "/user/close_zdjs",
      name: "close_zdjs",
      component: () => import("@/views/user_yh/subaccount_fzsh/close_zdjs/index"),
    },
    { //手工结算查询  
      path: "/user/close_sgjs",
      name: "close_sgjs",
      component: () => import("@/views/user_yh/subaccount_fzsh/close_sgjs/index"),
    },
    { //分账退款查询
      path: "/user/refund_fztk",
      name: "refund_fztk",
      component: () => import("@/views/user_yh/subaccount_fzsh/refund_fztk/index"),
    },
    { //分账交易查询
      path: "/user/deal_fzjy",
      name: "deal_fzjy",
      component: () => import("@/views/user_yh/subaccount_fzsh/deal_fzjy/index"),
    },
    { //账户管理
      path: "/user/account_zhgl",
      name: "account_zhgl",
      component: () => import("@/views/user_yh/subaccount_fzsh/account_zhgl/index"),
    },
    { //分账商户列表详情
      path: "/user/subaccountlist_xq",
      name: "subaccountlist_xq",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/subaccountlist_xq"),
      meta: {
        tab: '分账商户 - 详情'
      }
    },
    { //分账商户列表新增配置信息
      path: "/user/add_subaccountlist_pz",
      name: "add_subaccountlist_pz",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist_pz"),
      meta: {
        title: '新增分账商户'
      }
    },
    { //分账商户列表新增分账商户信息
      path: "/user/add_subaccountlist_shop",
      name: "add_subaccountlist_shop",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist_shop"),
      meta: {
        tab: '分账商户 - 详情'
      }
    },
    { //分账商户列表新增分账商户信息-电子账簿信息
      path: "/user/add_subaccountlist_electron",
      name: "add_subaccountlist_electron",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist_electron"),
      meta: {
        tab: '分账商户 - 详情'
      }
    },
    { //分账商户列表新增分账商户信息-电子账簿信息-配置
      path: "/user/add_subaccountlist_electron_pz",
      name: "add_subaccountlist_electron_pz",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist_electron_pz"),
      meta: {
        tab: '分账商户 - 详情'
      }
    },
    { //分账商户列表新增结算信息
      path: "/user/add_subaccountlist_js",
      name: "add_subaccountlist_js",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist_js"),
      meta: {
        title: '新增分账商户'
      }
    },
    { //分账商户列表新增基本信息
      path: "/user/add_subaccountlist",
      name: "add_subaccountlist",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist"),
      meta: {
        title: '新增分账商户',
        tab: '新增分账商户'
      }
    },
    { //分账商户列表修改基本信息
      path: "/user/add_subaccountlist_edit",
      name: "add_subaccountlist_edit",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/add_subaccountlist"),
      meta: {
        title: '新增分账商户',
        tab: '编辑分账商户'
      }
    },
    { //分账商户列表
      path: "/user/subaccountlist_shlb",
      name: "subaccountlist_shlb",
      component: () => import("@/views/user_yh/subaccount_fzsh/subaccountlist_shlb/index"),
      meta: {
        title: '分账商户列表',
        tab: '分账商户列表'
      }
    },

    { //电子账簿商户列表
      path: "/user_yh/ledger/index",
      name: "ledger_index",
      component: () => import("@/views/user_yh/ledger/index"),
      meta: {
        title: '电子账簿商户列表',
        tab: '电子账簿商户列表'
      }
    },
    { //电子账簿商户列表-新增-基本信息
      path: "/user_yh/ledger/add_jbxx",
      name: "ledger_addjbxx",
      component: () => import("@/views/user_yh/ledger/add_jbxx"),
      meta: {
        title: '新增电子账簿商户 - 基本信息',
        tab: '新增电子账簿商户 - 基本信息'
      }
    },
    { //电子账簿商户列表-新增-关联渠道
      path: "/user_yh/ledger/add_glqd",
      name: "ledger_addglqd",
      component: () => import("@/views/user_yh/ledger/add_glqd"),
      meta: {
        title: '新增电子账簿商户 - 关联渠道',
        tab: '新增电子账簿商户 - 关联渠道'
      }
    },
    { //电子账簿商户列表-新增-补充材料
      path: "/user_yh/ledger/add_bccl",
      name: "ledger_addbccl",
      component: () => import("@/views/user_yh/ledger/add_bccl"),
      meta: {
        title: '新增电子账簿商户 - 补充材料',
        tab: '新增电子账簿商户 - 补充材料'
      }
    },
    { //电子账簿商户列表-详情-基本信息
      path: "/user_yh/ledger/detail_jbxx",
      name: "ledger_detail_jbxx",
      component: () => import("@/views/user_yh/ledger/detail_jbxx"),
      meta: {
        title: '电子账簿商户 - 基本信息',
        tab: '电子账簿商户 - 基本信息'
      }
    },
    { //电子账簿商户列表-详情-基本信息-编辑
      path: "/user_yh/ledger/detail_jbxx_edit",
      name: "ledger_detail_jbxx_edit",
      component: () => import("@/views/user_yh/ledger/detail_jbxx_edit"),
      meta: {
        title: '电子账簿商户 - 基本信息 - 编辑',
        tab: '电子账簿商户 - 基本信息 - 编辑'
      }
    },
    { //电子账簿商户列表-详情-结算信息
      path: "/user_yh/ledger/detail_jsxx",
      name: "ledger_detail_jsxx",
      component: () => import("@/views/user_yh/ledger/detail_jsxx"),
      meta: {
        title: '电子账簿商户 - 结算信息',
        tab: '电子账簿商户 - 结算信息'
      }
    },
    { //电子账簿商户列表-详情-关联渠道
      path: "/user_yh/ledger/detail_glqd",
      name: "ledger_detail_glqd",
      component: () => import("@/views/user_yh/ledger/detail_glqd"),
      meta: {
        title: '电子账簿商户 - 关联渠道',
        tab: '电子账簿商户 - 关联渠道'
      }
    },
    { //电子账簿商户列表-详情-电子账簿
      path: "/user_yh/ledger/detail_dzzb",
      name: "ledger_detail_dzzb",
      component: () => import("@/views/user_yh/ledger/detail_dzzb"),
      meta: {
        title: '电子账簿商户 - 电子账簿',
        tab: '电子账簿商户 - 电子账簿'
      }
    },
    { //电子账簿商户列表-详情-电子账簿-详情
      path: "/user_yh/ledger/detail_dzzb_info",
      name: "ledger_detail_dzzb_info",
      component: () => import("@/views/user_yh/ledger/detail_dzzb_info"),
      meta: {
        title: '电子账簿商户 - 电子账簿 - 详情',
        tab: '电子账簿商户 - 电子账簿 - 详情'
      }
    },
    { //电子账簿商户列表-详情-产品信息
      path: "/user_yh/ledger/detail_cpxx",
      name: "ledger_detail_cpxx",
      component: () => import("@/views/user_yh/ledger/detail_cpxx"),
      meta: {
        title: '电子账簿商户 - 产品信息',
        tab: '电子账簿商户 - 产品信息'
      }
    },
    { //电子账簿商户列表-详情-补充材料
      path: "/user_yh/ledger/detail_bccl",
      name: "ledger_detail_bccl",
      component: () => import("@/views/user_yh/ledger/detail_bccl"),
      meta: {
        title: '电子账簿商户 - 补充材料',
        tab: '电子账簿商户 - 补充材料'
      }
    },

    { //代理商管理详情
      path: "/user/agentlist_xq",
      name: "agentlist_xq",
      component: () => import("@/views/user_yh/agent_dlsgl/agentlist_dlslb/agentlist_xq"),
      meta: {
        tab: '代理商 - 基本信息'
      }
    },
    { //代理商管理 新增产品信息
      path: "/user/add_agentlist_cp",
      name: "add_agentlist_cp",
      component: () => import("@/views/user_yh/agent_dlsgl/agentlist_dlslb/add_agentlist_cp"),
      beforeEnter: (to, from, next) => {
        if (to.query.id) {
          to.meta.tab = '代理商 - 产品信息'
        } else {
          to.meta.tab = '新增代理商 - 产品信息'
        }
        next()
      },
      // meta: {
      //   title: '新增代理商',
      //   tab: '代理商 - 配置信息'

      // }
    },
    { //代理商管理 新增结算信息
      path: "/user/add_agentlist_js",
      name: "add_agentlist_js",
      component: () => import("@/views/user_yh/agent_dlsgl/agentlist_dlslb/add_agentlist_js"),
      beforeEnter: (to, from, next) => {
        if (to.query.id) {
          to.meta.tab = '代理商 - 结算信息'
        } else {
          to.meta.tab = '新增代理商 - 结算信息'
        }
        next()
      },
      // meta: {
      //   title: '新增代理商',
      //   tab: '代理商 - 结算信息'
      // }
    },
    { //代理商管理 新增基本信息
      path: "/user/add_agentlist_xx",
      name: "add_agentlist_xx",
      component: () => import("@/views/user_yh/agent_dlsgl/agentlist_dlslb/add_agentlist_xx"),
      meta: {
        title: '新增代理商',
        tab: '新增代理商 - 基本信息'
      }
    },
    { //代理商管理 修改基本信息
      path: "/user/add_agentlist_xx_edit",
      name: "add_agentlist_xx_edit",
      component: () => import("@/views/user_yh/agent_dlsgl/agentlist_dlslb/add_agentlist_xx"),
      meta: {
        tab: '代理商 - 编辑基本信息'
      }
    },
    { //代理商管理
      path: "/user/agentlist_dlslb",
      name: "agentlist_dlslb",
      component: () => import("@/views/user_yh/agent_dlsgl/agentlist_dlslb/index"),
      meta: {
        title: '代理商列表',
        tab: '代理商列表'
      }
    },
    { //机构管理  新增支付机构基本信息
      path: "/user/add_organization_jbxx",
      name: "add_organization_jbxx",
      component: () => import("@/views/user_yh/paying_zfjg/organization_jggl/add_organization_jbxx"),
      meta: {
        title: '新增机构',
        tab: '新增机构列表'
      }
    },
    { //机构管理  新增支付机构基本信息
      path: "/user/edit_organization_jbxx",
      name: "edit_organization_jbxx",
      component: () => import("@/views/user_yh/paying_zfjg/organization_jggl/add_organization_jbxx"),
      beforeEnter: (to, from, next) => {
        if (to.query.active == "产品信息") {
          to.meta.tab = '机构详情 - 产品信息'
        } else {
          to.meta.tab = '机构详情 - 编辑信息'
        }
        next()
      },
      // meta: {
      //   tab: '机构详情 - 产品信息'
      // }
    },
    { //机构管理  新增支付机构基本信息
      path: "/user/organization_xp",
      name: "organization_xp",
      component: () => import("@/views/user_yh/paying_zfjg/organization_jggl/organization_xp"),
      meta: {
        tab: '机构详情 - 基本信息'
      }
    },
    { //机构管理
      path: "/user/organization_jggl",
      name: "organization_jggl",
      component: () => import("@/views/user_yh/paying_zfjg/organization_jggl/index"),
      meta: {
        title: '机构管理',
        tab: '机构列表'
      }
    },
    { //商户详情配置信息
      path: "/user/businessList_shlb/addcommercial_pzxx1",
      name: "addcommercial_pzxx1",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_pzxx1"),
      meta: {
        tab: '商户 - 配置信息'
      }
    },
    { //商户详情终端信息
      path: "/user/businessList_shlb/addcommercial_dkxq_edit",
      name: "addcommercial_dkxq_edit",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_dkxq_edit"),
      meta: {
        tab: '商户 - 终端信息'
      }
    },
    { //新增商户产品信息
      path: "/user/businessList_shlb/addcommercial_pzxx",
      name: "addcommercial_pzxx",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_pzxx"),
      meta: {
        title: '新增商户',
        tab: '新增商户 - 产品信息'
      }
    },
    { //新增商户产品信息
      path: "/user/businessList_shlb/addcommercial_pzxx_edit",
      name: "addcommercial_pzxx_edit",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_pzxx_edit"),
      meta: {
        title: '编辑商户',
        tab: '商户 - 产品信息'
      }
    },
    { //新增商户渠道信息  配置页面
      path: "/user/businessList_shlb/ditch_pz",
      name: "ditch_pz",
      component: () => import("@/views/user_yh/businessList_shlb/ditch_pz"),
      meta: {
        title: '新增商户',
        tab: '新增商户 - 渠道配置'
      }
    },
    { //新增商户渠道信息  配置页面
      path: "/user/businessList_shlb/ditch_pz_edit",
      name: "ditch_pz_edit",
      component: () => import("@/views/user_yh/businessList_shlb/ditch_pz_edit"),
      meta: {
        title: '编辑商户',
        tab: '商户 - 渠道配置'
      }
    },
    { //新增商户渠道信息
      path: "/user/businessList_shlb/addcommercial_qdxx",
      name: "addcommercial_qdxx",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_qdxx"),
      meta: {
        title: '新增商户',
        tab: '新增商户 - 渠道信息'
      }
    },
    { //新增商户渠道信息
      path: "/user/businessList_shlb/addcommercial_qdxx_edit",
      name: "addcommercial_qdxx_edit",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_qdxx_edit"),
      meta: {
        title: '编辑商户',
        tab: '商户 - 渠道信息'
      }
    },
    { //新增商户结算信息
      path: "/user/businessList_shlb/addcommercial_jsxx",
      name: "addcommercial_jsxx",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_jsxx"),
      meta: {
        title: '新增商户',
        tab: '新增商户 - 结算信息'
      }
    },
    { //新增商户结算信息 ------------------------------------------------------------------------------------------------------------------------------------
      path: "/user/businessList_shlb/addcommercial_jsxx_edit",
      name: "addcommercial_jsxx_edit",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_jsxx_edit"),
      meta: {
        title: '编辑商户',
        tab: '商户 - 结算信息'
      }
    },
    { //新增商户基本信息
      path: "/user/businessList_shlb/addcommercial_jbxx",
      name: "addcommercial_jbxx",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_jbxx"),
      meta: {
        title: '新增商户',
        tab: '新增商户 - 基本信息'
      }
    },
    { //新增商户基本信息
      path: "/user/businessList_shlb/addcommercial_jbxx_edit",
      name: "addcommercial_jbxx_edit",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_jbxx_edit"),
      meta: {
        title: '编辑商户',
        tab: '编辑商户基本信息'
      }
    },
    { //商户列表
      path: "/user/businessList_shlb",
      name: "businessList_shlb",
      component: () => import("@/views/user_yh/businessList_shlb/index"),
      meta: {
        title: '商户管理',
        tab: '商户列表'
      }
    },
    { //商户详情
      path: "/user/businessList_shxq",
      name: "businessList_shxq",
      component: () => import("@/views/user_yh/businessList_shlb/addcommercial_shxq"),
      meta: {
        tab: '商户 - 基本信息'
      }
    },
    { //终端IP配置
      path: "/user/ip_setting",
      name: "ip_setting",
      component: () => import("@/views/user_yh/ip/setting"),
      meta: {
        tab: '终端IP配置'
      }
    },
    { //新增终端IP配置
      path: "/user/ip_setting_add",
      name: "ip_setting_add",
      component: () => import("@/views/user_yh/ip/setting/edit"),
      beforeEnter: (to, from, next) => {
        if (to.query.id) {
          to.meta.tab = '编辑终端IP配置'
        } else {
          to.meta.tab = '新增终端IP配置'
        }
        next()
      },
    },
    { //智能路由配置
      path: "/user/intelligence/index",
      name: "/user/intelligence/index",
      component: () => import("@/views/user_yh/intelligence/index"),
      meta: {
        tab: '智能路由配置'
      }
    },
    { //智能路由配置
      path: "/user/intelligenceAdd",
      name: "/user/intelligenceAdd",
      component: () => import("@/views/user_yh/intelligence/intelligenceAdd"),
      meta: {
        tab: '智能路由配置 - 新增'
      }
    },
    { //默认
      path: "/user404",
      name: "default",
      component: () => import("@/views/default"),
    }
  ]
},

]
