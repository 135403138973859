export default [{
		path: "/",
		name: "transaction",
		redirect: '/deal',
		component: () => import("@/views/trading_jy/index"),
		children: [{ //海关 订单详情
				path: "/customs_detail", 
				name: "customs_detail",
				component: () => import("@/views/trading_jy/cross_border/customs/detail"),
				meta: {
					tab: '查看海关推单详情'
				}
			},
			{ //海关
				path: "/customs",
				name: "customs",
				component: () => import("@/views/trading_jy/cross_border/customs/index"),
				meta: {
					tab: '海关推单查询'
				}
			},
			{ //账户转账详情
				path: "/transfer_xq",
				name: "transfer_xq",
				component: () => import("@/views/trading_jy/subaccount_fzgl/transfer_zhzz/refund_xq"),
				meta: {
					tab: '查看账户转账详情'
				}
			},
			{ //账户转账查询
				path: "/transfer_zhzz",
				name: "transfer_zhzz",
				component: () => import("@/views/trading_jy/subaccount_fzgl/transfer_zhzz/index"),
				meta: {
					tab: '账户转账查询'
				}
			},
			{ //担保查询详情  
				path: "/guarantee_xq",
				name: "guarantee_xq",
				component: () => import("@/views/trading_jy/subaccount_fzgl/guarantee_dbcx/guarantee_xq"),
				meta: {
					tab: '查看担保详情'
				}
			},
			{ //担保查询  
				path: "/guarantee_dbcx",
				name: "guarantee_dbcx",
				component: () => import("@/views/trading_jy/subaccount_fzgl/guarantee_dbcx/index"),
				meta: {
					tab: '担保查询'
				}
			},
			{ //自动结算查询  
				path: "/automatic_zdjs",
				name: "automatic_zdjs",
				component: () => import("@/views/trading_jy/subaccount_fzgl/automatic_zdjs/index"),
				meta: {
					title: '自动结算查询',
					tab: '自动结算查询'
				}
			},
			{ //手工结算查询详情  
				path: "/refund_sgjs_detail",
				name: "refund_sgjs_detail",
				component: () => import("@/views/trading_jy/subaccount_fzgl/refund_sgjs/refund_xq"),
				meta: {
					tab: '查看手工结算详情'
				}
			},
			{ //手工结算查询  
				path: "/refund_sgjs",
				name: "refund_sgjs",
				component: () => import("@/views/trading_jy/subaccount_fzgl/refund_sgjs/index"),
				meta: {
					title: '手工结算查询',
					tab: '手工结算查询'
				}
			},
			{ //手工结算审核  
				path: "/refund_sgjssh",
				name: "refund_sgjssh",
				component: () => import("@/views/trading_jy/subaccount_fzgl/refund_sgjssh/index"),
				meta: {
					title: '手工结算审核',
					tab: '手工结算审核'
				}
			},
			{ //手工结算审核-详情  
				path: "/refund_sgjssh_xq",
				name: "refund_sgjssh_xq",
				component: () => import("@/views/trading_jy/subaccount_fzgl/refund_sgjssh/refund_sgjssh_xq"),
				meta: {
					title: '手工结算审核详情',
					tab: '手工结算审核详情'
				}
			},
			{ //分账代付查询详情  
				path: "/account_xq2",
				name: "account_xq2",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_fzdf/account_xq"),
				meta: {
					tab: '查看分账代付详情'
				}
			},
			{ //分账代付查询  
				path: "/account_fzdf",
				name: "account_fzdf",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_fzdf/index"),
				meta: {
					tab: '分账代付查询'
				}
			},
			{ //分账退款查询详情  
				path: "/account_xq1",
				name: "account_xq1",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_fztk/account_xq"),
				meta: {
					tab: '查看分账退款详情'
				}
			},
			{ //分账退款查询  
				path: "/account_fztk",
				name: "account_fztk",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_fztk/index"),
				meta: {
					tab: '分账退款查询'
				}
			},
			{ //分账交易查询详情
				path: "/account_fzjy_detail",
				name: "account_fzjy_detail",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_fzjy/account_xq"),
				meta: {
					tab: '查看分账交易详情'
				}
			},
			{ //分账交易查询
				path: "/account_fzjy",
				name: "account_fzjy",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_fzjy/index"),
				meta: {
					tab: '分账交易查询'
				}
			},
			{ //账户管理  
				path: "/account_zhgl",
				name: "account_zhgl",
				component: () => import("@/views/trading_jy/subaccount_fzgl/account_zhgl/index"),
				meta: {
					title: '账户列表',
					tab: '账户管理'
				}
			},
			{ //代付详情
				path: "/paidquery_qx",
				name: "paidquery_qx",
				component: () => import("@/views/trading_jy/payment_fk/paidquery_dfcx/paidquery_qx"),
				meta: {
					tab: '查看代付详情'
				}
			},
			{ //代付查询
				path: "/paidquery_dfcx",
				name: "paidquery_dfcx",
				component: () => import("@/views/trading_jy/payment_fk/paidquery_dfcx/index"),
				meta: {
					tab: '代付查询'
				}
			},
			{ //账户列表代付表格
				path: "/another_tab",
				name: "another_tab",
				component: () => import("@/views/trading_jy/payment_fk/accountlist_zhlb/another_tab"),
				meta: {
					title: '账户列表'
				}
			},
			{ //账户列表明细
				path: "/user_order_detail",
				name: "user_order_detail",
				component: () => import("@/views/trading_jy/payment_fk/accountlist_zhlb/order_detail"),
				meta: {
					tab: '查看账户明细'
				}
			},
			{ //账户列表
				path: "/another_df",
				name: "another_df",
				component: () => import("@/views/trading_jy/payment_fk/accountlist_zhlb/index"),
				meta: {
					title: '账户列表',
					tab: '账户列表'
				}
			},
			{ //交易详情
				path: "/details_xq",
				name: "details_xq",
				component: () => import("@/views/trading_jy/gathering_sk/deal_jycx/details_xq"),
				meta: {
					tab: '查看交易详情'
				}
			},
			{ //交易查询
				path: "/deal",
				name: "deal",
				component: () => import("@/views/trading_jy/gathering_sk/deal_jycx/index"),
				meta: {
					tab: '交易查询'
				}
			},
			{ //退款查询
				path: "/refund_tkcx",
				name: "refund_tkcx",
				component: () => import("@/views/trading_jy/gathering_sk/refund_tkcx/index"),
				meta: {
					tab: '退款查询'
				}
			},
			{ //退款详情
				path: "/reund_xq",
				name: "reund_xq",
				component: () => import("@/views/trading_jy/gathering_sk/refund_tkcx/reund_xq"),
				meta: {
					tab: '查看退款查询详情'
				}
			},
			{ //退款详情
				path: "/refund_tkxq",
				name: "refund_tkxq",
				component: () => import("@/views/trading_jy/gathering_sk/refund_tksh/refund_tkxq"),
				meta: {
					tab: '查看退款审核详情'
				}
			},
			{ //退款审核
				path: "/refund_tksh",
				name: "refund_tksh",
				component: () => import("@/views/trading_jy/gathering_sk/refund_tksh/index"),
				meta: {
					tab: '退款审核'
				}
			},
      { //电子账簿-账簿信息
        path: "/electronInfo",
        name: "electron_book",
        component: () => import("@/views/trading_jy/electron_book/electronInfo"),
        meta: {
          tab: '账簿信息'
        }
      },
      { //电子账簿-入金查询
        path: "/deposit_rjcx",
        name: "deposit_rjcx",
        component: () => import("@/views/trading_jy/electron_book/deposit_rjcx"),
        meta: {
          tab: '入金查询'
        }        
      },
      { //电子账簿-出金查询
        path: "/gildwwd_cjcx",
        name: "gildwwd_cjcx",
        component: () => import("@/views/trading_jy/electron_book/gildwwd_cjcx"),
        meta: {
          tab: '出金查询'
        }        
      },
      { //默认
        path: "/transaction404",
        name: "default",
        component: () => import("@/views/default")
      }
		]
	},

]
