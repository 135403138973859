<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  watch: {
    $route: {
      handler: function (val) {
        this.getRole(val.fullPath);
        if(val.name == "login"){
          document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width,initial-scale=1.0')
        }else{
          document.querySelector('meta[name="viewport"]').setAttribute('content', 'user-scalable=yes')
        }
        if (val.name == "default") {
          sessionStorage.setItem("active_tab", "");
          return;
        }
        if (val.meta.tab) {
          let data = {
            title: val.meta.tab,
            fullPath: val.fullPath,
          };
          let tabs = sessionStorage.getItem("tabs")
            ? JSON.parse(sessionStorage.getItem("tabs"))
            : [];
          if (JSON.stringify(tabs).indexOf(JSON.stringify(data)) === -1) {
            tabs.push(data);
            sessionStorage.setItem("tabs", JSON.stringify(tabs));
          }
          sessionStorage.setItem("active_tab", val.fullPath);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    getRole(fullPath) {
      const _resources = sessionStorage.getItem("userInfo")
        ? (JSON.parse(sessionStorage.getItem("userInfo")).resources += "")
        : "";
      if (_resources == "ALL") {
        return;
      }
      switch (fullPath) {
        case "/user/organization_jggl":
          if (_resources.split(";").indexOf("ACCOUNT:ORG:ORGS") === -1) {
            this.$router.push("/user404");
          }
          break;
        case "/deal":
          if (_resources.split(";").indexOf("TRADE:RECEIPT:ORDERS") === -1) {
            this.$router.push("/transaction404");
          }
          break;
        case "/data/deal_jytz":
          if (_resources.split(";").indexOf("DATA:PLATFORM:TRADE_SURVEY") === -1) {
            this.$router.push("/data404");
          }
          break;
          case "/operation/manage_jydd":  // /operation/deal_jytz
          if (_resources.split(";").indexOf("OPERATION:ORDERS:TRADE") === -1) { // OPERATION:NOTICES:ORDER 
            this.$router.push("/operation404");
          }
          break;
        case "/management/role_jsgl":
          if (_resources.split(";").indexOf("SYSTEM:SYSTEM:ROLES") === -1) {
            this.$router.push("/management404");
          }
          break;
      }
    },
  },
  mounted(){
    window.onload = () =>{
      var seetoken = localStorage.getItem("token");
      this.$store.commit("setToken",seetoken);
    }
  }
};
</script>
<style>
@import "../src/assets/css/color.css";
html {
  overflow-y: scroll;
}
#app {
  width: 100%;
  background: #fff;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
dl,
td,
dd,
hr,
input,
body,
figure {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #333;
}

body {
  width: 100%;
  font-size: 12px;
  font-family: "微软雅黑";
}
/* body {
  width: 100vw;
  overflow: hidden;
} */
img {
  display: block;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: normal;
}

em,
i {
  font-style: normal;
}

input {
  outline: none;
}

*:focus {
  outline: none;
}

/*去掉INPUT在部分浏览器里点击里的外边线*/
.clear:after {
  content: " ";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  /*或overflow:hidden;*/
}

.float_left {
  float: left;
}

.width100 {
  width: 100%;
}

.hidden1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cover_view {
  z-index: 9999 !important;
}

.overAccount_ul_title i,
.uploader1 h4 i {
  font-family: "Microsoft YaHei";
  color: #48b8b6;
  padding-right: 3px;
}

/* 重置公共样式 */
/* 多选 */
.el-radio .el-radio__input.is-checked .el-radio__inner,
.el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #48b8b6;
  border-color: #48b8b6;
}

.el-radio .el-radio__input.is-checked + .el-radio__label,
.el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #48b8b6;
}

/* 对话框/信息框 */
.el-dialog .el-dialog__body {
  padding: 0;
}

.el-dialog .el-dialog__title,
.el-message-box .el-message-box__title {
  font-size: 16px;
  color: #333333;
}

.el-dialog .el-dialog__headerbtn .el-dialog__close,
.el-message-box .el-message-box__headerbtn .el-message-box__close {
  color: #48b8b6;
}

.el-message-box .el-message-box__btns button:nth-child(1) {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
  border-color: #dcdfe6;
}

.el-message-box .el-message-box__btns button:nth-child(2) {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

/* 输入框 */
.el-input .el-input__inner {
  color: #333333;
  border: 1px solid #d7dae2;
}

.el-menu{
    overflow-x: hidden;
    -ms-overflow-x:hidden;
    /* -ms-overflow-style: none; */
}

/* 菜单 */
.el-menu-item {
  position: relative;
  color: #333333 !important;
}

.el-menu-item.is-active {
  background-color: rgba(72, 184, 182, 0.05) !important;
}

.el-menu-item.is-active::before {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #48b8b6;
}

/* 分页 */
.el-pagination {
  float: right;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #76d8cb !important;
}

.el-pagination.is-background .el-pager li.active:hover {
  color: #fff !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #76d8cb !important;
}

/* 页签 */
.el-tabs--top .el-tabs__item .el-icon-close {
  opacity: 0;
  margin-left: -14px;
  transition: all 0.3s;
}

.el-tabs--top .el-tabs__item:hover .el-icon-close {
  opacity: 1;
  margin-left: 5px;
}

.el-tabs--top .el-tabs__item:hover,
.el-tabs--top .el-tabs__item.is-active {
  color: #48b8b6;
}

.el-tabs--top .el-tabs__item.is-active::after {
  content: "";
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #48b8b6;
}

.el-tabs--top .el-tabs__active-bar {
  display: none;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px !important;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}

.el-tabs--top .el-tabs__nav-wrap::after {
  display: none;
}

.el-tabs--top .el-tabs__item {
  height: 45px;
  line-height: 45px;
}

.el-tabs--top .el-tabs__item:focus.is-active.is-focus:not(:active) {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input:hover {
  border-color: #48b8b6 !important;
}

input:focus {
  border-color: #48b8b6 !important;
}

.el-pager .active {
  border: 0;
}

.el-select-dropdown__item.selected {
  color: #48b8b6 !important;
}

.el-date-table td.current:not(.disabled) span {
  background-color: #48b8b6 !important;
  color: #fff !important;
}

.el-date-table td.today span {
  color: #48b8b6 !important;
}

.el-date-table td.available:hover {
  color: #48b8b6 !important;
}

.el-button--text {
  /* color: #48b8b6 !important; */
}
.el-upload-list__item .el-icon-close-tip {
  display: none !important;
}
.el-button.is-plain:focus,
.el-button.is-plain:hover {
  border-color: #48b8b6 !important;
  color: #48b8b6 !important;
}

.el-dialog .el-select,
.el-dialog .el-input {
  width: 100%;
}

.avatar-uploader .el-upload-dragger {
  width: 100%;
  height: 100%;
  background-color: rgba(246, 246, 246, 1);
}

.avatar-uploader .el-upload-dragger.is-dragover {
  border: none;
  background-color: rgba(72, 184, 182, 0.8) !important;
}

.avatar-uploader .el-upload-dragger.is-dragover .avatar-uploader-icon {
  color: #ffffff;
}

.avatar-uploader .el-upload-dragger {
  border: none;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #48b8b6 !important;
}

.el-upload--picture-card:hover,
.el-upload:focus {
  color: #606266 !important;
}

.avatar-uploader .el-upload:hover,
.avatar-uploader .el-upload {
  border: 0 !important;
}

.el-checkbox__inner:hover,
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #dcdfe6 !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #48b8b6 !important;
  background-color: #48b8b6 !important;
}

.el-picker-panel__shortcut:hover {
  color: #48b8b6 !important;
}

.el-picker-panel__icon-btn:hover {
  color: #48b8b6 !important;
}

.el-date-picker__header-label.active,
.el-date-picker__header-label:hover {
  color: #48b8b6 !important;
}

.el-radio__inner:hover {
  border-color: #48b8b6 !important;
}

.el-dialog__headerbtn:focus .el-dialog__close,
.el-dialog__headerbtn:hover .el-dialog__close {
  color: #48b8b6 !important;
}
.el-tooltip__popper.is-light {
  background-color: #fff !important;
  color: #333333 !important;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05) !important;
  border: 0 !important;
}
.el-textarea__inner:focus {
  border-color: #c0ccc4 !important;
}
.el-loading-spinner .path {
  stroke: #48b8b6 !important;
}

.el-aside ::-webkit-scrollbar{
  display: none;
}
.el-aside{
  overflow: hidden;
}

/* 浏览器滚动条整体样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/*浏览器滚动条里面小方块*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgb(149, 149, 149);
}
/*浏览器滚动条里面轨道*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}



/* 搜索板块 */
.con_from p>i {
  padding-right: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
.con_from p:last-child{
  padding-bottom:0px;
}
.con_from p {
  padding-bottom: 20px;
  height: 40px;
}

.con_from  .el-input__icon{
  line-height: 40px !important;
}
.con_from .lineP{
  display: flex;
  flex-flow: wrap;
  margin: 20px 0px 0px;
  width: 100%;
}
.con_from .lineP:first-child{
  margin: 0px;
}
.con_from .lineP .item {
  display: flex;
  align-content: center;
  height: 40px;
  line-height: 40px;
  width: 27%;
  margin-left: 9.5%;
  font-size: 14px;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.con_from .lineP .itembtn {
  display: flex;
  margin-left: auto;
  width: 30%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1px;
  box-sizing: border-box;
}
.con_from .lineP .item > i {
  width: 60px; 
  text-align: right;
  margin-right: 10px;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select {
  width: calc(100% - 60px - 10px);
}
.con_from .lineP .item .el-select .el-input{
  width: 100%;
}
.con_from .lineP .itemTime{
  /* width: 49.723%; */
  width: 49.58%;
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 60px - 24.67px - 10px) / 2);
}
.con_from .lineP .itemTwo{
  width: 38%;
  margin-left: 4%;
}
.con_from .lineP .itemTwo:first-child{
  margin-left: 0%;
}
.con_from .lineP  .itemTwobtn{
  width: 20%;
}

/* 数量， */
.jiaoyi {
  width: 100%;
  height: 90px;
  position: relative;
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.jiaoyi_box {
  float: left;
  width: 23.65%;
  height: 90px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.jiaoyi_box p {
  padding-top: 13px;
  width: 100%;
  text-align: center;
}
.jiaoyi_box p b {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(72, 184, 182, 1);
}

.jiaoyi_box p i {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
}

/* 表格样式 */
.tab1 {
  /* min-height: 600px; */
  min-height: 660px; 
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.tab1 .tab {
  border-collapse: collapse;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}
.tab1 td {
  padding: 0 6px;
  word-break: normal;
  white-space: nowrap;
  box-sizing: border-box;
}
.tab1 .tab_title {
  background: rgba(244, 245, 246, 1);
  border-top: 1px solid rgba(220, 224, 230, 1);
}
.tab1 .tab tr {
  padding-left: 20px;
  height: 60px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
}
.tab1 .tab tr td p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
.tab1 .lianjie {
  padding: 0 5px;
  color: #008aff;
  cursor: pointer;
}
/* 按钮样式 */
.outputBt {
  float: right;
  width: 90px;
  height: 36px;
  line-height: 36px;
  padding: 0 !important;
  border-color: #d7dae2 !important;
  background-color: #fff !important;
  margin-left: 20px;
  padding: 0;
  color: #606266 !important;
}

.outputBt:hover,.outputBt:focus {
  background-color: #fff !important;
  color: #48b8b6 !important;
}

.searchBt , .searchBt:focus , .searchBt:hover{
  float: right;
  background: #48b8b6 !important;
  border-color: #5aafab !important;
  width: 90px;
  height: 36px;
  line-height: 36px;
  padding: 0 !important;
  color: #fff !important;
}
/* 页面切换样式 */
.header {
  width: 100%;
  height: 32px;
  border-bottom: 1px solid rgba(220, 224, 230, 1);
  position: relative;
  top: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.header span {
  display: inline-block;
  height: 100%;
  width: 100px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  cursor: pointer;
}
.header span.active {
  color: var(--themeColor);
  border-bottom: 2px solid var(--themeColor);
  box-sizing: border-box;
  height: 32px;
} 

.header #fzcolor{
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--themeColor);
  border-bottom: 3px solid var(--themeColor);
  box-sizing: border-box;
}

.header_switch{
  line-height: 40px;
  width: 100%;
  height: 40px;
  margin-top:20px;
}
.header_switch  div {
  cursor: pointer;
}
.header_switch  .right {
  display: inline-block;
  float: right;
  width: 74px;
  height: 40px;
  color: var(--title);
  font-size: 12px;
  text-align: center;
  line-height: 40px;
}

.header_switch  .right.active {
  color: var(--themeColor);
  box-sizing: border-box;
  border-bottom: 2px solid var(--themeColor);
} 



/* 上传图片 删除 统一样式 */
.avatar-uploader{position: relative;}
.avatar-uploader .avatar-uploader-span{    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    padding: 5px;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    color: black;
    text-align: right;
    z-index: 99;
    cursor: pointer;}
</style>
