export default [{
		path: "/management",
		name: "management",
		redirect: '/management/role_jsgl',
		component: () => import("@/views/management_gl/index"),
		children: [{
				path: "/config_all",
				name: "config_all",
				component: () => import("@/views/management_gl/config/index"),
				meta: {
					title: '其他配置',
					tab: '其他配置'
				}
			},
			{
				path: "/secondary/index",
				name: "secondary_auth",
				component: () => import("@/views/management_gl/system_xtgl/secondary/index"),
				meta: {
					title: '用户隐私二次认证',
					tab: '用户隐私二次认证'
				}
			},
			{
				path: "/management/logon_dlrz",
				name: "logon_dlrz",
				component: () => import("@/views/management_gl/system_xtgl/logon_dlrz/index"),
				meta: {
					title: '登录日志',
					tab: '登录日志'
				}
			},
			{
				path: "/management/staff_yggl",
				name: "staff_yggl",
				component: () => import("@/views/management_gl/system_xtgl/staff_yggl/index"),
				meta: {
					title: '员工管理',
					tab: '员工管理'
				}
			},
			{ //新增角色
				path: "/management/add_role",
				name: "add_role",
				component: () => import("@/views/management_gl/system_xtgl/role_jsgl/add_role"),
			},
			{
				path: "/management/role_jsgl",
				name: "role_jsgl",
				component: () => import("@/views/management_gl/system_xtgl/role_jsgl/index"),
				meta: {
					title: '角色管理',
					tab: '角色管理'
				}
			},
			{ //默认
				path: "/management404",
				name: "default",
				component: () => import("@/views/default"),
			}
		]
	},

]
